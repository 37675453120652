<!-- studentId -->
<template>
  <div class="content has_breadcrumb">
    <!--  section-info1 -->
    <section-info
      :data="formDataList"
      :renderPass="renderPass"
      title="Student ID"
      :iconEdit="false"
      submitBtnTxt="Save"
      :isShowLabel="true"
      :readonly.sync="readonly"
      @submit="submitEdit"
      @cancel="handleCancel"
      @input="input"
      :inline="true"
      class="content-student"
      v-loading="loading"
    >
      <template slot="renderHtml" slot-scope="scope">
        <div v-if="scope.data.key === 'autoGenerateID'" class="md-field autoId">
          <gg-switch
            v-model="aotuSwitch"
            active-color="#1A73E8"
            inactive-color="#445469"
            @change="changeSwitch"
          />
        </div>
        <baseGGSelect
          v-if="scope.data.key === 'separator'"
          v-model="scope.data.value"
          :key="scope.data.key"
          :ref="scope.data.key"
          inline
        >
          <md-option
            v-for="item in scope.data.roleList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </md-option>
        </baseGGSelect>

        <gg-input
          v-if="scope.data.key === 'initialNo'"
          :ref="scope.data.key"
          v-model="scope.data.value"
          :placeholder="scope.data.placeholder"
          :error="initialNoErrorObj"
        />

        <div v-else-if="scope.data.key == 'preview'" class="md-field">
          {{ preview }}
        </div>
      </template>
    </section-info>
    <Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { lightFormat } from "date-fns";
export default {
  components: {},
  data() {
    return {
      formDataList: [
        {
          placeholder: "Auto-generated ID",
          key: "autoGenerateID",
          value: false,
          renderHtml: true,
        },
        {
          placeholder: "Prefix",
          key: "prefix",
          value: "",
          autofocus: true,
          // reg: /^[\S]{0,4}$/,
          // errorText: "名字为1-4个字，不含有空格",
          disabled: false,
        },
        {
          placeholder: "Suffix",
          key: "suffix",
          value: "",
          // reg: /^[\S]{0,8}$/,
          // errorText: "名字为1-8个字，不含有空格",
          disabled: false,
        },
        {
          placeholder: "Separator",
          key: "separator",
          value: "",
          roleList: [
            // 去除掉空
			  // { value: "", label: "" },
            { value: "-", label: "-" },
            { value: "#", label: "#" },
            { value: "/", label: "/" }
          ],
          renderHtml: true,
          disabled: false,
        },
        {
          placeholder: "No of digits",
          key: "digits",
          value: "",
          // reg: /^[0-9]{1}$/,
          // errorText: "个位数字，不含有空格",
          disabled: false,
        },
        {
          placeholder: "Initial no.",
          key: "initialNo",
          value: "",
          reg: /^[0-9]*$/,
          errorText: "Numeric only",
          disabled: false,
          renderHtml: true,
        },
        {
          placeholder: "Preview",
          key: "preview",
          value: this.preview,
          renderHtml: true,
        },
      ],
      renderPass: false,
      slotData: ["separator"],
      isDisabled: true,
      readonly: false,
      aotuSwitch: false,
      studentObj: {
        prefix: "",
        separator: "",
        digits: null,
        suffix: "",
        initialNo: null,
      },
      snackbar: { isShow: false, content: "" },
      loading: false,
      initialNoErrorObj: {
        show: true,
        message: "ooo",
      },
    };
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user,
    }),
    preview() {
      let preview = "";
      let arr = ["prefix", "separator", "digits", "suffix", "initialNo"];
      let ID = 0;
      this.formDataList.forEach((item) => {
        this.studentObj[item.key] = item.value;
      });
      let { prefix, separator, digits, suffix, initialNo } = {
        ...this.studentObj,
      };
      let digitNum = Number(digits) + 1;
      if (initialNo) {
        if (initialNo.length <= digits) {
          ID = this.combineNum(initialNo, Number(digits));
        }
        //  else {
        //   console.log(22);
        //   this.formDataList.forEach((item) => {
        //     if (item.key === "initialNo") {
        //       item.reg = new RegExp("/^[0-9]{" + digitNum + "}$/", "g");
        //       item.errorText = "Exceeds No. of digits";
        //     }
        //   });
        // }
      } else {
        ID = "1";
      }
      preview = prefix + (prefix ? separator : '') + ID + (suffix ? separator : '') + suffix;
      return preview;
    },
  },
  watch: {
    formDataList: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          let aaa = this.slotData.every((item) => {
            return (this.$refs[item] && !!this.$refs[item].value) || (this.$refs["separator"].value=='');
          });
          let initialNoVal = this.$refs.initialNo.value;
          if (initialNoVal) {
            let initialNoPass = /^[0-9]*$/.test(initialNoVal);
            if (initialNoPass) {
              this.initialNoErrorObj = {
                show: false,
                message: "",
              };
            } else {
              this.initialNoErrorObj = {
                show: true,
                message: "Numeric only",
              };
            }
			
            if ((this.studentObj.initialNo.length > this.studentObj.digits) && this.studentObj.digits !='') {
              this.initialNoErrorObj = {
                show: true,
                message: "Numeric only",
              };
            }
          }
          this.renderPass = aaa;
          console.log("renderPass:", this.renderPass);
        });
      },
    },
  },
  methods: {
    _getData() {
      var data = {
        userId: this.user.userId,
        dataLevel: this.module.moduleName,
        dataType: this.module.moduleType,
      };
      this.loading = true;
      Ajax.post("/usermanage/setting/getList", data)
        .then((res) => {
          let response = res.data[0];
          let studentObj = JSON.parse(response.value);
          this.loading = false;
          this.formDataList.forEach((item) => {
            item.value = studentObj[item.key];
          });
          this.aotuSwitch = studentObj.autoGenerateID === "1" ? true : false;
		  setTimeout(()=>{
			  this.renderPass=false
		  },100)
        })
        .catch(() => {
          this.loading = false;
        });
    },
    changeSwitch(val) {
      // this.formDataList.forEach((item) => {
      //   item.disabled = val;
      // });
	   this.renderPass=true
    },
    input(res) {
      console.log(res);
    },
    handleCheck(val) {
      this.checkedData = val;
      // console.log(this.locations);
    },
    handleCancel() {
      this.readonly = false;
      this.$router.go(-1);
    },
    submitEdit() {
      this.readonly = false;

      var formData = {
        userId: this.user.userId,
        dataLevel: this.module.moduleName,
        dataType: this.module.moduleType,
        type: this.user.userType,
        value: {
          prefix: this.studentObj.prefix,
          separator: this.studentObj.separator,
          initialNo: this.studentObj.initialNo,
          digits: this.studentObj.digits,
          suffix: this.studentObj.suffix,
          autoGenerateID: this.aotuSwitch ? "1" : "0",
        },
      };
      this.loading = true;
      Ajax.post("/usermanage/setting/save", formData).then((res) => {
        let response = res.data;
        this.handleSuspend(res.message);
        this.loading = false;
        this.$router.go(-1);
      });
    },
    combineNum(num, length) {
      return (Array(length).join("0") + num).slice(-length);
    },
    handleSuspend(content) {
      this.snackbar.content = content;
      this.snackbar.isShow = true;
    },
  },
  created() {
    this.module = { ...this.$route.query };
    this._getData();
  },
};
</script>
<style lang='less' scoped>
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}
.content-student {
  /deep/.not-readonly-content {
    max-width: calc(100% + 80px);
    margin-left: -80px;
    .label {
      padding-left: 80px;
      width: 240px;
      flex-basis: 240px;
    }
    .md-field {
      width: calc(50% - 240px);
	  align-items: center;
    }
    .autoId {
      align-items: center;
      width: calc(100% - 240px);
    }
  }
}
</style>